(function () {
  'use strict';

  angular
    .module('neo.home.comite')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.comite', {
        url: '/comite',
        templateUrl: 'home/comite/comite.tpl.html',
        data: {
          townHall: undefined,
          initDate: undefined,
          endDate: undefined
        },
        controller: 'comiteCtrl',
        controllerAs: 'vm',
        resolve: {
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          },
          currentSeason: function(CurrentSeason) {
            return CurrentSeason.get().$promise;
          }
        }
      });
  }
}());
